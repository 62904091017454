<template>
  <div class="footer" :class="{'hideMobile': (isHome && !scrolled) || isOpenOverlay}" >
    <div class="container-lg h-100">
      <!--MOBILE-->
      <div class="row m-0 h-100 d-lg-none align-items-center bottom-env-padding">
        <div class="col-2 p-0 text-center">
          <div class="row m-0 align-items-center justify-content-center" @click="isProfile = true" v-if="user" >
            <div class="col-12 p-0 text-center mb-1 font-gold" >
              <IconUserProfile />
            </div>
            <div class="col-12 p-0 text-center font11 font-gold" >
              Account
            </div>
          </div>
          <div class="row m-0 align-items-center justify-content-center" @click="isLogin = true" v-else >
            <div class="col-12 p-0 text-center mb-1 font-gold">
              <IconUserProfile />
            </div>
            <div class="col-12 p-0 text-center font-gold font11">
              Login
            </div>
          </div>
          <transition name="fade">
            <ProfilePopup :user="user" v-if="isProfile" @close="isProfile = false"/>
            <LoginPopup v-else-if="isLogin" @close="isLogin = false" :redirect="true"/>
          </transition>
        </div>
        <div class="col text-center">
          <Button color="red" width="100" btnText="Donate Now" @buttonClicked="goTo('donate')" v-if="showDonateButton" />
        </div>
        <div class="col-2 p-0 text-center" @click="goTo('checkout/0')">
          <div class="row m-0 align-items-center justify-content-center" >
            <div class="col-12 p-0 text-center mb-1 font-gold">
              <IconCart />
              <NotificationBadge />
            </div>
            <div class="col-12 p-0 text-center font-gold font11">
              Cart
            </div>
          </div>
        </div>
      </div>
      <!--END MOBILE-->
      <!--DESKTOP-->
      <div class="row m-0 align-items-center h-100 d-none d-lg-flex">
        <div class="col-12">
          <div class="row m-0 align-items-center justify-content-center">
            <div class="col-6" :class="{'text-center': isUK}">
              Copyright {{currentYear}} © All rights Reserved. Al-Imdaad Foundation<span v-if="isUK"> UK</span>
            </div>
            <div class="col-6" v-if="!isUK">
              <img class="img-fluid" src="../assets/images/payment_logos_footer.svg"  />
            </div>
          </div>
        </div>
      </div>
      <!--END DESKTOP-->
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'

import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('../components/Button.vue')),
    NotificationBadge: defineAsyncComponent(() => import('../components/NotificationBadge.vue')),
    IconCart: defineAsyncComponent(() => import('../components/icons/IconCart.vue')),
    IconUserProfile: defineAsyncComponent(() => import('../components/icons/IconUserProfile.vue')),
    LoginPopup: defineAsyncComponent(() => import('@/views/auth/LoginPopup.vue')),
    ProfilePopup: defineAsyncComponent(() => import('./ProfilePopup.vue'))
  },
  name: 'Footer',
  props: ['scrolled', 'isHome'],
  data () {
    return {
      isLogin: false,
      isProfile: false
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'isOpenOverlay'
    ]),
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    },
    showDonateButton () {
      if (this.$route.name === 'Donate Now' || this.$route.name === 'Donation Checkout' || this.$route.name === 'Project') {
        return false
      } else {
        return true
      }
    },
    currentYear () {
      return DateTime.now().toFormat('yyyy')
    }
  },
  methods: {
    goTo (val) {
      window.scrollTo(0, 0)
      this.$router.push('/' + val)
    }
  }
}
</script>

<style scoped>
.footer {
  position: fixed;
  width: 100vw;
  height: calc(75px + env(safe-area-inset-bottom));
  background-color: var(--green-color-dark);
  bottom: 0;
  left: 0;
  z-index: 3;
}
.hideMobile {
  display: none;
}
.font11 {
  font-size: 11px;
}
.font-gold {
  color: var(--gold-color);
}
@media (min-width: 992px) {
  .footer {
    height: 45px;
    font-size: 15px;
    position: relative;
    color: var(--green-color-light);
    width: 100%;
  }
  .hideMobile {
    display: block;
  }
  .social_icons {
    font-size: 20px;
  }
}
</style>
